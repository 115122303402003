/*
AZUL: #204d8b
CELESTE: #5aa7dc
*/
.App {
  text-align: center;
  height:100vh;
  width:100vw;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.row, .col{
  padding:0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-logo2 {
  margin-top: 3vmin;
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}

.scrollspydiv{
  position: relative;
  height: 100vh;
  padding-top: 56px;
  width: 100vw;
  overflow-y: scroll;
}
#menu-bar{
  z-index: 1;
}
.ssCategory{
  min-height: calc(90vh - 56px);
  padding: 10px;
  margin: 0px;
  width: 100vw;
  z-index: 0;
}

#scrollspyInicio{
  background-color: #fff;
}
#scrollspyServicios{
  background-color: #5aa7dc;
}
#scrollspyNosotros{
  background-color: #fff;
}
#scrollspyContacto{
  background-color: #204d8b;
}

.sucursalesGrid{
  margin-left: 0px !important;
  margin-right: 0px !important;
  
}
.sucursalesGrid p{
    margin-bottom: 0px;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar .nav-item a.nav-link {
  padding: 1em;
}
.nav-link.active {
  background-color: #204d8b;
  color: #ffffff !important;
}

